<template>
  <nav
    class="absolute top-0 left-0 right-0 z-10 items-center py-5"
    :class="isMenuOpen ? 'bg-sc-gray-darker': 'bg-sc-blue' "
  >
    <div class="flex justify-between px-4 lg:mx-28">
      <a
        href="/"
        title="Inicio"
      >
        <img
          :src="require('@assets/images/logo-white.svg')"
          class="w-32 h-12 duration-200 hover:opacity-50"
          alt="Logo Supercartola"
        >
      </a>
      <div class="hidden space-x-4 md:flex">
        <btn
          href="/faqs"
          effect="colorChange"
          color="purpleFilled"
        >
          {{ $t('message.landing.button.faq') }}
        </btn>
        <btn
          href="/users/sign_in"
          effect="translate"
          color="whiteBorder"
        >
          {{ $t('message.landing.button.signIn') }}
        </btn>
        <btn
          href="/users/sign_up"
          effect="translate"
          color="whiteFilled"
        >
          {{ $t('message.onboarding.createEntityStep.add') }}
        </btn>
      </div>
      <div
        class="flex md:hidden"
      >
        <button
          @click="displayMenu"
        >
          <div class="p-4 bg-white rounded-full">
            <inline-svg
              :src="menuImageUrl"
              class="w-4 h-4 fill-current text-gray-darker"
            />
          </div>
        </button>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isMenuOpen"
        class="h-screen px-20 mt-10 text-center space-y-7 bg-gray-darker align-items-center"
      >
        <a
          href="/faqs"
          title="Preguntas frecuentes"
          class="font-semibold text-white"
        >
          {{ $t('message.landing.button.faq') }}
        </a>
        <btn
          href="/users/sign_in"
          effect="translate"
          color="whiteBorder"
        >
          {{ $t('message.landing.button.signIn') }}
        </btn>
        <btn
          href="/users/sign_up"
          effect="translate"
          color="darkFilled"
        >
          {{ $t('message.onboarding.createEntityStep.add') }}
        </btn>
      </div>
    </transition>
  </nav>
</template>
<script setup lang='ts'>
import { ref, computed } from 'vue';
import Btn from '../btn.vue';

const isMenuOpen = ref(false);

const menuImageUrl = computed(() => {
  if (isMenuOpen.value) return new URL('@assets/images/cross.svg', import.meta.url).href;

  return new URL('@assets/images/menu.svg', import.meta.url).href;
});

const displayMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
</script>
