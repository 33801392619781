<template>
  <div class="flex flex-col items-center mx-auto my-28 lg:container lg:w-9/12">
    <h2 class="mx-5 mb-10 text-2xl font-bold text-center lg:w-8/12 sm:text-4xl">
      {{ $t(`message.landing.infoSection.${landingKind}.header`) }}
    </h2>
    <div class="flex flex-wrap justify-around gap-5">
      <landing-info-card
        v-for="card in infoCards"
        :key="card.name"
        :icon="card.icon"
        :title="$t(`message.landing.infoSection.${landingKind}.card.${card.name}.title`)"
      >
        {{ $t(`message.landing.infoSection.${landingKind}.card.${card.name}.description`) }}
      </landing-info-card>
    </div>
  </div>
</template>

<script setup lang='ts'>
import { ref } from 'vue';
import LandingInfoCard from './landing-info-card.vue';

interface Props {
  landingKind?: string;
}

const props = withDefaults(defineProps<Props>(), {
  landingKind: 'base',
});

const infoCards = ref(
  [
    {
      name: 'connect',
      icon: 'connect',
    },
    {
      name: 'integrations',
      icon: 'integration',
    },
    {
      name: 'filterMovements',
      icon: 'movement',
    },
    {
      name: 'createOrganization',
      icon: 'organization',
    },
    {
      name: 'optimizeTime',
      icon: 'optimize',
    },
    {
      name: 'organizeMovements',
      icon: 'organize',
    },
  ],
);
</script>
