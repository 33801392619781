import api from './index';

const basePath = (entityId) => `/api/v1/entities/${entityId}/bank_movements`;

export default {
  getMovements(queryParams, entityId) {
    return api({
      method: 'get',
      url: basePath(entityId),
      params: queryParams,
    });
  },
  getMovement({ entityId, movementId }) {
    const path = `${basePath(entityId)}/${movementId}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  changeStatus({ entityId, movementId }) {
    const path = `${basePath(entityId)}/${movementId}/status`;

    return api({
      method: 'post',
      url: path,
    });
  },
  removeCheck({ entityId, movementId }) {
    const path = `${basePath(entityId)}/${movementId}/remove_check`;

    return api({
      method: 'patch',
      url: path,
    });
  },
  updateExtraColumns(data, { entityId, movementId }) {
    const path = `${basePath(entityId)}/${movementId}/extra_columns`;

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
  getComments({ entityId, movementId }) {
    const path = `${basePath(entityId)}/${movementId}/comments`;

    return api({
      method: 'get',
      url: path,
    });
  },
  postComment({ entityId, movementId, content }) {
    const path = `${basePath(entityId)}/${movementId}/comments`;

    return api({
      method: 'post',
      url: path,
      params: { content },
    });
  },
  deleteComment({ entityId, movementId, commentId }) {
    const path = `${basePath(entityId)}/${movementId}/comments/${commentId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
  getLastMovement({ entityId }) {
    const path = `${basePath(entityId)}/last`;

    return api({
      method: 'get',
      url: path,
    });
  },
  approveAllMovements({ entityId, params }) {
    const path = `${basePath(entityId)}/approve_all`;

    return api({
      method: 'patch',
      url: path,
      params,
    });
  },
};
