<template>
  <div class="container flex-col items-center mx-auto border-t my-28 md:flex">
    <h2 class="my-10 text-2xl font-bold text-center sm:text-4xl lg:w-8/12">
      {{ $t('message.landing.inviteSection.header') }}
    </h2>
    <div clas="align-items-center">
      <btn
        href="mailto:supercartola@platan.us"
        effect="translate"
        color="purpleFilled"
        class="mb-4"
      >
        {{ $t('message.landing.button.emailContact') }}
      </btn>
      <btn
        :href="calendly"
        effect="translate"
        color="purpleBorder"
      >
        {{ $t('message.landing.button.videoContact') }}
      </btn>
    </div>
  </div>
</template>
<script setup lang='ts'>
import { ref } from 'vue';
import Btn from '../btn.vue';

const calendly = ref(import.meta.env.VITE_DEFAULT_CALENDLY_LINK);
</script>
