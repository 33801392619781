<template>
  <div class="flex-col items-center px-4 lg:w-9/12 lg:mx-auto md:flex my-28 ">
    <h2 class="mb-10 text-2xl font-bold text-center lg:w-9/12 sm:text-4xl">
      {{ $t('message.landing.tabSection.header') }}
    </h2>
    <div>
      <div>
        <ul class="flex justify-center">
          <li class="mx-2 text-center cursor-pointer">
            <button
              :aria-label="$t('message.landing.tabSection.tab.cartola')"
              class="p-3 text-xs font-semibold uppercase rounded-lg hover:bg-sc-blue-lighter"
              @click="toggleTabs(1)"
              :class="{
                'text-gray-800 bg-white ': openTab !== 1,
                'text-gray-800 bg-sc-blue-lighter': openTab === 1
              }"
            >
              {{ $t('message.landing.tabSection.tab.cartola') }}
            </button>
          </li>
          <li class="mx-2 text-center cursor-pointer">
            <button
              :aria-label="$t('message.landing.tabSection.tab.organization')"
              class="p-3 text-xs font-semibold uppercase rounded-lg hover:bg-sc-blue-lighter"
              @click="toggleTabs(2)"
              :class="{
                'text-gray-800 bg-white ': openTab !== 2,
                'text-gray-800 bg-sc-blue-lighter': openTab === 2
              }"
            >
              {{ $t('message.landing.tabSection.tab.organization') }}
            </button>
          </li>
        </ul>
      </div>
      <div class="flex flex-col my-4">
        <div class="flex-auto px-4 py-5 tab-content tab-space">
          <img
            v-if="openTab === 1"
            :src="require('@assets/images/cartolas.png')"
            alt="Cartolas"
            class="rounded-md shadow-lg md:rounded-2xl"
          >
          <img
            v-else-if="openTab === 2"
            :src="require('@assets/images/organizaciones.png')"
            alt="Organizaciones"
            class="rounded-md shadow-lg md:rounded-2xl"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>
import { ref } from 'vue';

const openTab = ref(1);

const toggleTabs = (tabNumber: number) => {
  openTab.value = tabNumber;
};
</script>
