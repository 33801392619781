import { camelize } from 'humps';
import EntitiesApi from '../api/entities';

const initialState = {
  entityConfiguration: null,
  entityId: null,
  balancesConfiguration: { pending: false, total: false, available: false },
};

export const mutations = {
  setEntityId(state, payload) {
    state.entityId = payload;
  },
  setEntityConfiguration(state, payload) {
    const columns = payload.hiddenColumns.map(name => camelize(name));
    state.entityConfiguration = { ...payload, hiddenColumns: columns };
    state.balancesConfiguration = payload.balancesConfiguration;
  },
};

export const actions = {
  setEntityId({ commit }, entityId) {
    commit('setEntityId', entityId);
  },
  getEntityConfiguration({ commit, state }) {
    return EntitiesApi.getEntityConfiguration(state.entityId)
      .then(({ data: { entityConfiguration } }) => {
        commit('setEntityConfiguration', entityConfiguration);

        return entityConfiguration;
      });
  },
};

export const getters = {
  extraColumnsSchema(state) {
    const configuration = state.entityConfiguration;
    const extraColumnsSchemaArray = configuration && configuration.extraColumnsSchemaArray;
    if (!extraColumnsSchemaArray) return {};

    const schema = {};
    extraColumnsSchemaArray.forEach((columnSchema) => {
      schema[columnSchema.name] = columnSchema;
    });

    return schema;
  },
  extraColumnsNames(state) {
    const schema = getters.extraColumnsSchema(state);

    return schema && Object.values(schema).map(col => col.name);
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
