import api from './index';

const basePath = (entityId) => `/api/v1/entities/${entityId}/bank_accounts`;

export default {
  getAccounts(entityId) {
    return api({
      method: 'get',
      url: basePath(entityId),
    });
  },
  editAccount({ entityId, accountId, isActive, newName }) {
    const path = `${basePath(entityId)}/${accountId}`;

    return api({
      method: 'patch',
      url: path,
      data: { isActive, newName },
    });
  },
};
