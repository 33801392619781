import AccountsApi from '../api/bank-accounts';

const initialState = {
  bankAccounts: [],
};

export const mutations = {
  setBankAccounts: (state, payload) => {
    state.bankAccounts = payload;
  },
};

export const getters = {
  activeBankAccounts(state) {
    const activeAccounts = state.bankAccounts.filter(account => account.isActive);

    return activeAccounts;
  },
};

export const actions = {
  getBankAccounts({ commit, rootState }) {
    AccountsApi.getAccounts(rootState.entities.entityId)
      .then(({ data: { accounts } }) => {
        commit('setBankAccounts', accounts);
      });
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
