function parseName(fieldName) {
  const info = decodeURI(fieldName.split('ExtraColumns')[1]);
  const name = info.split('[')[0];

  return name;
}

function parseType(fieldName) {
  return fieldName.split('ExtraColumns')[0];
}

function parseDate(dateString) {
  const dates = dateString.split(',').map((date) => new Date(date));

  return { start: dates[0], end: dates[1] };
}

function parseEnum(fieldName) {
  const info = decodeURI(fieldName.split('ExtraColumns')[1]);
  const regularExpression = /\[(.*?)\]/g;
  const content = decodeURI(info.match(regularExpression)[0]);

  return content.replace('[', '').replace(']', '');
}

export { parseName, parseType, parseDate, parseEnum };
