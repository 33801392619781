<template>
  <component
    :is="tag"
    class="flex items-center self-start justify-center w-full py-2 text-sm font-semibold transition duration-700 transform border-2 rounded cursor-pointer sm:px-5 sm:w-auto"
    :class="[effectClasses, colorClasses]"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    effect: { type: String, default: 'translate' },
    color: { type: String, default: 'purpleFilled' },
  },
  computed: {
    tag() {
      return this.$attrs.href ? 'a' : 'button';
    },
    effectClasses() {
      const classes = {
        translate: 'filter drop-shadow-xl hover:-translate-y-1',
        colorChange: 'hover:bg-sc-blue-light hover:border-sc-blue-light',
      };

      return classes[this.effect];
    },
    colorClasses() {
      const classes = {
        purpleFilled: 'bg-sc-blue text-white hover:text-white border-sc-blue',
        purpleBorder: 'bg-white text-sc-blue hover:text-sc-blue border-sc-blue',
        whiteFilled: 'bg-white text-sc-blue  hover:text-sc-blue border-white',
        whiteBorder: 'text-white hover:text-white border-white',
        darkFilled: 'bg-white text-sc-grar-darker  hover:text-sc-blue border-white',
      };

      return classes[this.color];
    },
  },
};
</script>
