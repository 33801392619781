export default {
  message: {
    accounts: {
      needsUpdate: 'Una cuenta se está sincronizando, este proceso puede tardar hasta 15 minutos.',
      requiresLogin: 'Una o más cuentas deben ser reconectadas, entra a configuración',
      selectAll: 'Seleccionar todas',
      unselectAll: 'Deseleccionar todas',
      type: {
        checkingAccount: 'Cta Cte',
        savingsAccount: 'Cta Ahorro',
        sightAccount: 'Cta Vista',
        rutAccount: 'Cta Rut',
        lineOfCredit: 'Línea Cdto',
        creditCard: 'T. Cdto',
        longNames: {
          checkingAccount: 'Cuenta Corriente',
          savingsAccount: 'Cuenta Ahorro',
          sightAccount: 'Cuenta Vista',
          rutAccount: 'Cuenta Rut',
          lineOfCredit: 'Línea de Crédito',
          creditCard: 'Tarjeta de Crédito',
        },
      },
      institution: {
        clBancoDeChile: 'Banco de Chile',
        clBancoSantander: 'Banco Santander',
        clBancoBci: 'Banco Bci',
        clBancoEstado: 'Banco Estado',
        clBancoItau: 'Banco Itaú',
        shortNames: {
          clBancoBci: 'B. Bci',
          clBancoBice: 'B. Bice',
          clBancoDeChile: 'B. Chile',
          clBancoEstado: 'B. Est',
          clBancoItau: 'B. Itaú',
          clBancoSantander: 'B. San',
          clBancoScotiabank: 'B. Scot',
          clBancoSecurity: 'B. Secur',
        },
      },
      displayMode: {
        custom: 'Nombre de cuenta',
        number: 'Número de cuenta',
      },
    },
    navbar: {
      createEntity: 'Crear Supercartola',
      logout: 'Cerrar Sesión',
    },
    settings: {
      accountsTab: {
        accounts: 'Cuentas',
        availableBalances: 'Saldos disponibles',
        balance: 'Balance',
        connectAccount: 'Conectar cuenta',
        connectAccounts: 'Conecta tus cuentas',
        reconnectAccount: 'Cuenta se debe reconectar',
        reconnectAccountTooltip: 'Selecciona "Conectar cuenta" e ingresa tus credenciales de nuevo',
        noAccountsMessage: 'Así podremos ir al banco a traer tus movimientos.',
        looklikeMessage: 'Mira cómo se verá tu Supercartola',
        connectAccountModal: {
          selectAccountType: 'Selecciona el tipo de cuenta:',
          accountType: {
            business: 'Empresa',
            individual: 'Personal',
          },
        },
      },
      columnsTab: {
        columns: 'Columnas',
        customColumns: 'Columnas personalizadas',
        addColumn: 'Agregar columna',
        editColumn: 'Editar columna',
        updateHiddenColumns: 'Guardar',
        modal: {
          addButton: 'Agregar',
          applyButton: 'Apply',
          editButton: 'Guardar',
          namePlaceholder: 'Ingresa el nombre de la columna',
          defaultValuePlaceholder: 'Ingresa el valor por defecto',
          optionalDefaultValuePlaceholder: 'Valor por defecto (opcional)',
          optionsPlaceholder: 'Presiona "Enter" para ingresar tag',
          errors: {
            blankName: 'El nombre no puede estar vacío',
            emptyTags: 'Debes agregar al menos una opción',
          },
        },
        table: {
          removeButton: 'Eliminar',
          editButton: 'Editar',
          header: {
            name: 'Nombre',
            type: 'Tipo',
            options: 'Opciones',
            defaultValue: 'Valor por defecto',
            activateFilters: 'Activar Filtro',
          },
          enabledFilter: 'Activado',
          disabledFilter: 'Desactivado',
        },
        types: {
          text: 'Texto',
          date: 'Fecha',
          toggle: 'Toggle',
          tags: 'Tags',
        },
        hiddenColumns: 'Columnas ocultas',
        hiddenColumnsPlaceholder: 'Busca el nombre de la columna a ocultar',
      },
      header: {
        goToTutorials: 'Quieres aprender mas sobre Supercartola? Ingresa a la sección de tutoriales.',
        goToMovementsButton: 'Ver movimientos',
        settings: 'Configuración',
      },
      membersTab: {
        members: 'Miembros',
        addMember: 'Agregar miembro',
        role: 'Rol',
        roles: {
          admin: 'Administrador',
          member: 'Miembro',
        },
        table: {
          editButton: 'Editar',
          removeButton: 'Eliminar',
          header: {
            email: 'Email',
            firstName: 'Nombre',
            lastName: 'Apellido',
            role: 'Rol',
            defaultFilter: 'Filtro Predeterminado',
            downloadsEnabled: 'Descargar Cartolas',
          },
          pagination: {
            message: '{total} resultados',
            previousButton: 'Anterior',
            nextButton: 'Siguiente',
          },
          downloadsEnabled: {
            true: 'Activado',
            false: 'Desactivado',
          },
          enoughAdmins: 'No se puede cambiar el rol. Se requiere al menos un usuario administrador.',
        },
      },
      sheetsTab: {
        sheets: 'Google Sheets',
        googleSheet: 'Google sheet',
        connectedGoogleSheet: 'Google sheet conectada',
        previousButton: 'Anterior',
        nextButton: 'Siguiente',
        steps: {
          createGoogleSheet: 'Crear Google sheet',
          givePermissions: 'Dar permisos',
          getLink: 'Obtener enlace',
          connectToSupercartola: 'Conectar a Supercartola',
          contents: {
            createGoogleSheet: 'Crea una nueva hoja de cálculo en Google Sheets',
            givePermissions: 'Selecciona la opción de Compartir y agrega con rol de editor a:',
            getLink: 'Copia el enlace de Google para compartir con personas y grupos',
            connectToSupercartola: 'Pegar enlace acá',
          },
          copyButton: 'Copiar',
          finishButton: 'Finalizar Conexión',
          connectionSuccess: 'Tu planilla fue conectada, anda a revisarla!',
          emptyUrlError: 'Ingrese un enlace no vacío',
          connectionError: 'Ocurrió un error. Revisa el enlace y permisos',
        },
        copyLinkButton: 'Copiar enlace',
        removeButton: 'Eliminar',
        copyLinkSuccess: 'Enlace copiado',
        disconnectMessage: 'Esta acción desconectará la Google Sheet anterior. ¿Deseas continuar?',
        disconectionError: 'Hubo un error, por favor vuelve a intentarlo',
        disconectionSuccess: 'Planilla desconectada',
        backButton: 'Volver',
        continueButton: 'Continuar',
      },
      advancedTab: {
        advanced: 'Avanzado',
        advancedSettings: 'Configuración avanzada',
        movementsNumber: {
          title: 'Movimientos por Página',
          save: 'Guardar',
        },
        deleteEntity: 'Eliminar Supercartola',
        deleteButton: 'Eliminar',
        balances: 'Mostrar saldos',
        totalBalance: 'Contable',
        pendingBalance: 'Pendiente de Aprobación',
        availableBalance: 'Disponible',
      },
      filtersTab: {
        filters: 'Filtros',
        defaultFilters: 'Filtros predeterminados',
        table: {
          header: {
            name: 'Nombre',
            filters: 'Filtros aplicados',
          },
        },
        createFilter: 'Crear filtro',
        filterName: 'Nombre',
        addFilterName: 'Ingrese un nombre para su filtro',
        saveFilter: 'Guardar',
        addFilter: 'Agregar filtro',
      },
    },
    table: {
      button: {
        changeStatus: 'Cambiar estado',
        view: 'Ver',
        update: 'Actualizar',
      },
      confirmationModal: {
        confirmation: 'Confirmación',
        cancel: 'Cancelar',
      },
      emptyMessage: 'No se ha encontrado información.',
      commentPlaceholder: {
        single: 'Escribe un comentario',
        many: 'Escribe un comentario para todos estos movimientos',
      },
      header: {
        account: 'Cuenta | Cuentas',
        action: 'Acción',
        approve: 'Aprobar',
        approveAll: 'Aprobar todo',
        all: 'Todas | Todos',
        amount: 'Monto | Montos',
        clearFilters: 'Limpiar filtros',
        currency: 'Moneda',
        date: 'Fecha',
        dateFilter: 'Fecha Transacción',
        description: 'Descripción',
        inbound: 'Ingreso',
        issuing: 'Emisor',
        lastAccountUpdate: 'Última cuenta actualizada',
        movements: 'Movimientos',
        originBank: 'Banco Emisor',
        others: 'Otros',
        outbound: 'Egreso',
        range: 'Rango | Rangos',
        status: 'Estado | Estados',
        transferences: 'Transferencias',
        textFilter: 'Buscar por descripción, comentario, nombre o RUT',
        totalBalance: 'Saldo contable',
        pendingBalance: 'Saldo pendiente de aprobación',
        availableBalance: 'Saldo disponible',
        sendRefreshIntent: 'Realizar actualización',
        refreshIntentLoading: 'Obteniendo nuevos movimientos...',
      },
      pagination: {
        message: '{from} - {to} resultados',
        previousButton: 'Anterior',
        nextButton: 'Siguiente',
      },
      status: {
        approved: 'Aprobado',
        pending: 'Pendiente',
      },
      loadingTimeMessage: 'Recuerda que los movimientos pueden tardar hasta 15 minutos en sincronizarse.',
      warning: {
        changeStatus: '¿Estás seguro que quieres cambiar el estado de este movimiento?',
        changeStatusAll: '¿Estás seguro que quieres aprobar todos estos movimientos?',
        changeStatusAllLastStep: `Esta acción aprobará todos los movimientos de esta Supercartola
                                  en base a los filtros actualmente seleccionados.`,
        changeStatusSelected: '¿Estás seguro que quieres aprobar estos movimientos?',
      },
      movementInfo: {
        postDate: 'Fecha Contable',
        transactionDate: 'Fecha Transacción',
        senderInfo: 'Cuenta Origen',
        holderRut: 'Rut Origen',
        movementId: 'Número Movimiento',
        typeName: 'Tipo de Movimiento',
        description: 'Descripción',
        referenceId: 'Número Referencia',
        comment: 'Comentario',
        approvedBy: 'Aprobado por',
        disapprove: 'Marcar Pendiente',
        approve: 'Aprobar',
        update: 'Actualizar',
        comments: 'Comentarios',
        commentPlaceholder: 'Escribe un comentario',
        commentButton: 'Comentar',
        confirmPending: '¿Estás seguro de que quieres marcar pendiente este movimiento?',
        confirmApproved: '¿Estás seguro de que quieres aprobar este movimiento?',
        deleteComment: 'Eliminar',
        enter: 'Enter',
      },
      waitingModal: {
        waitingMessageStart: 'Por favor, espera mientras',
        waitingMessageAnd: 'y',
        waitingMessageEnd: {
          singular: 'se termina de conectar',
          plural: 'se terminan de conectar',
        },
        sendEmail: {
          singular: 'Te enviaremos un correo cuando termine',
          plural: 'Te enviaremos un correo cuando terminen',
        },
        okButton: 'Entendido',
      },
    },
    tutorials: {
      back: 'Regresar a mi Supercartola',
      header: 'Guias de Usuario',
      body: 'Aquí encontrarás guias de usuario para descubrir las herramientas de Supercartola.',
    },
    onboarding: {
      continue: 'Continuar',
      back: 'Volver',
      createEntityStep: {
        add: 'Crea tu Supercartola',
        name: 'Nombre',
        button: 'Crear Supercartola',
        info: 'Cada supercartola agrupa tus cuentas bancarias, miembros y configuraciones. Puedes tener más de una.',
        error: 'Debes ingresar un nombre para tu supercartola',
      },
      fintocStep: {
        success: 'Supercartola creada!',
        accountConnected: 'Cuenta conectada!',
        waitingAccount: 'Conecta tu banco',
        connectAnotherAccount: 'Conectar otra cuenta',
        connectionInfo: 'La conexión se hace a través de Fintoc, cumpliendo con altos estándares de seguridad.',
        knowMore: 'Si quieres saber más, pincha aquí.',
        skipButtonText: 'Conectar después',
      },
      personalizeStep: {
        add: 'Personaliza tu Supercartola',
        question: 'Agrega etiquetas a tus movimientos. Partamos por identificar tus ingresos y gastos',
        name: 'Categorías',
        income: {
          name: 'Ingresos',
          question: '¿Qué categorías de Ingresos tienes?',
          placeholder: 'Prueba: "Venta al Contado"',
        },
        costs: {
          name: 'Gastos',
          question: '¿Qué categorías de Gastos tienes?',
          placeholder: 'Prueba: "Arriendo"',
        },
        ideas: 'Ideas para categorías: Venta al Contado, Arriendo, etc.',
        info: 'Puedes personalizar tu SuperCartola en la Configuración.',
      },
      successStep: {
        success: 'Configuración finalizada!',
        button: 'Ir a Supercartola',
      },
    },
    landing: {
      button: {
        signIn: 'Ingresa',
        faq: 'Preguntas frecuentes',
        emailContact: 'Hablemos por correo',
        videoContact: 'Agenda una videollamada',
        showQuestion: 'Mostrar pregunta',
        hideQuestion: 'Ocultar pregunta',
      },
      heroSection: {
        base: {
          header: 'Ahorra tiempo y gestiona tus movimientos desde una cartola a tu medida',
          body: `Crea tu Supercartola y olvídate de entrar al banco. Ahora todos los movimientos
          de tus cuentas estarán en un sólo lugar. Obtén de manera simple y flexible la información
          financiera que tu negocio necesita.`,
          cta: 'Pruébala ahora. Es gratis',
        },
        paymentValidation: {
          header: 'Ahorra tiempo y valida tus pagos con una cartola a tu medida',
          body: `Crea tu supercartola y olvídate de tener que acceder
          a tu cuenta bancaria para revisar si recibiste una transferencia.
          Supercartola centraliza tus movimientos e información financiera en un solo lugar`,
          cta: 'Pruébala ahora. Es gratis',
        },
        conciliation: {
          header: 'Simplifica tu conciliación bancaria y ahorra tiempo',
          body: `Crea tu Supercartola y maneja los movimientos de tus cuentas en un solo lugar.
          Revisa tus pagos, aprueba movimientos y complementa transacciones de manera fácil y flexible.`,
          cta: 'Pruébala ahora. Es gratis',
        },
      },
      infoSection: {
        base: {
          header: 'Haz todo lo que siempre has querido con tu cartola del banco.',
          card: {
            connect: {
              title: 'Conecta múltiples cuentas',
              description: `Conecta todos tus bancos y gestiona
              tus movimientos en un sólo lugar.`,
            },
            integrations: {
              title: 'Integración con otros software',
              description: `Ten la información sincronizada en Google Sheets.
              Arma flujos de caja o alimenta tu software de visualización de datos.`,
            },
            filterMovements: {
              title: 'Filtra movimientos',
              description: `Filtra por fecha, monto o rut y
              encuentra los movimientos que necesitas en tan solo un par de clicks.`,
            },
            createOrganization: {
              title: 'Trabaja con tu equipo',
              description: `Trabaja con todo tu equipo simultáneamente.
              Tú decides a qué movimientos tiene acceso cada miembro.`,
            },
            optimizeTime: {
              title: 'Añade información adicional',
              description: `Escribe comentarios, especificaciones o lo que necesites para complementar
              la información de tu cartola y compartirla.`,
            },
            organizeMovements: {
              title: 'Categoriza los movimientos',
              description: `Controla tus gastos e ingresos. Crea tus propias categorías
              y clasifica los movimientos según tus necesidades.`,
            },
          },
        },
        paymentValidation: {
          header: 'Haz todo lo que siempre has querido con tu cartola del banco.',
          card: {
            connect: {
              title: 'Conecta múltiples cuentas',
              description: `Conecta todos tus bancos y gestiona
              tus movimientos en un sólo lugar.`,
            },
            filterMovements: {
              title: 'Filtra movimientos',
              description: 'Encuentra los movimientos que necesitas de manera rápida y fácil.',
            },
            createOrganization: {
              title: 'Trabaja con tu equipo',
              description: `Permite a tu equipo ver los movimientos que necesiten. Verifica
              movimientos de forma sincronizada`,
            },
            optimizeTime: {
              title: 'Añade información adicional',
              description: 'Escribe comentarios, numeros de boleta o lo que necesites para organizar tus pagos.',
            },
            organizeMovements: {
              title: 'Categoriza los movimientos',
              description: `Controla tus gastos e ingresos. Crea tus propias categorías
              y clasifica los movimientos según tus necesidades.`,
            },
          },
        },
        conciliation: {
          header: 'Haz que tu proceso de conciliación sea más rápido y fácil.',
          card: {
            connect: {
              title: 'Conecta múltiples cuentas',
              description: `Conecta todos tus bancos y gestiona
              tus movimientos en un sólo lugar.`,
            },
            integrations: {
              title: 'Aprueba tus movimientos',
              description: 'Marca tus movimientos como aprobados una vez que los revisaste.',
            },
            filterMovements: {
              title: 'Filtra movimientos',
              description: 'Encuentra los movimientos que necesitas en un par de clicks.',
            },
            createOrganization: {
              title: 'Trabaja con tu equipo',
              description: `Permite a tu equipo ver los movimientos que necesiten. Verifica
              movimientos de forma sincronizada`,
            },
            optimizeTime: {
              title: 'Añade información para conciliar',
              description: `Escribe comentarios, números de factura o lo que necesites para complementar
              la información de tu cartola.`,
            },
            organizeMovements: {
              title: 'Categoriza los movimientos',
              description: `Controla tus gastos e ingresos. Crea tus propias categorías
              y clasifica los movimientos según tus necesidades.`,
            },
          },
        },
      },
      tabSection: {
        header: 'Sin trámites, es fácil. Configura tu Supercartola y comienza a ahorrar tiempo.',
        tab: {
          cartola: 'Cartola',
          organization: 'Organización',
        },
      },
      inviteSection: {
        header: '¿Todavía quieres saber más?',
      },
      faqSection: {
        header: 'Preguntas frecuentes',
        body: 'Encuentra aquí todo lo que necesitas saber de Supercartola',
      },
      footerSection: {
        poweredBy: 'Powered by',
        knowMore: '¿Tienes dudas?',
        writeUs: 'Escríbenos a',
        links: {
          fintoc: 'Fintoc',
          supercartolaEmail: "supercartola{'@'}platan.us",
          team: 'Equipo',
          platanus: 'Platanus',
          supercartola: 'Supercartola',
          blog: 'Blog',
          banks: 'Bancos disponibles',
        },
      },
    },
  },
};
