import { decamelize } from 'humps';

function keyParam(key, type) {
  return `${type}ExtraColumns${encodeURI(key)}`;
}

function keyParamEnum(key, type, enumName) {
  return `${type}ExtraColumns${encodeURI(key)}[${encodeURI(enumName)}]`;
}

function addParamToUrl(params, newParam) {
  params.set(decamelize(newParam.key), newParam.value);
}

export { keyParam, keyParamEnum, addParamToUrl };
