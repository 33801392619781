<template>
  <div class="w-full p-4 mb-3 bg-white border border-gray-200 drop-shadow-lg rounded-xl lg:w-10/12">
    <div class="flex justify-between">
      <p class="font-semibold">
        {{ topic }}
      </p>
      <button
        :aria-label="getBtnAriaLabel"
        @click="toggleAnswer"
      >
        <inline-svg
          :src="require('@assets/images/minus.svg')"
          class="w-6 h-6 cursor-pointer fill-current text-sc-blue-light hover:text-sc-blue-lighter"
          v-if="showAnswer"
        />
        <inline-svg
          :src="require('@assets/images/plus.svg')"
          class="w-6 h-6 cursor-pointer fill-current text-sc-blue-light hover:text-sc-blue-lighter"
          v-else
        />
      </button>
    </div>
    <div
      class="mt-5 text-left"
      v-if="showAnswer"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topic: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAnswer: false,
    };
  },
  methods: {
    toggleAnswer() {
      this.showAnswer = !this.showAnswer;
    },
    getBtnAriaLabel() {
      if (this.showAnswer) {
        return this.$t('message.landing.button.hideQuestion');
      }

      return this.$t('message.landing.button.showQuestion');
    },
  },
};
</script>
