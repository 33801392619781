import Vue from 'vue';
import Vuex from 'vuex';

import movements from './bank-movements';
import users from './users';
import entities from './entities';
import bankAccounts from './bank-accounts';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    movements,
    users,
    entities,
    bankAccounts,
  },
});

export default store;
