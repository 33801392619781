<template>
  <div class="items-center p-5 bg-white border border-gray-200 h-52 w-80 filter drop-shadow-lg rounded-2xl">
    <div class="flex items-center mb-4 space-x-4">
      <div class="flex items-center justify-center w-10 h-10 rounded-lg bg-sc-blue-lighter">
        <inline-svg
          :src="iconUrl"
          class="fill-current text-sc-blue-light"
        />
      </div>
      <p
        class="font-bold uppercase"
      >
        {{ title }}
      </p>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script setup lang='ts'>
import { computed } from 'vue';

interface Props {
  icon: string;
  title: string;
}

const props = defineProps<Props>();

const iconUrl = computed(() => {
  const baseUrl = new URL('@assets/images/abstract-icons', import.meta.url).href;

  return `${baseUrl}/${props.icon}.svg`;
});
</script>
