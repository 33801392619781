import UsersApi from '../api/users';

export const mutations = {
  setSession: (state, payload) => {
    state.currentUser = payload;
  },
  setAuthorizerUser: (state, payload) => {
    state.authorizerUser = payload;
  },
  setLoadingAuthorizerUser: (state, payload) => {
    state.loadingAuthorizerUser = payload;
  },
  setIsAdmin: (state, payload) => {
    state.isAdmin = payload;
  },
};

export const getters = {
  isClient(state) {
    return !!state.currentUser.isClient;
  },
};

export const actions = {
  getAuthorizerUser({ commit }, userId) {
    commit('setLoadingAuthorizerUser', true);
    UsersApi.getUser(userId)
      .then(({ data: { user } }) => {
        commit('setAuthorizerUser', user);
      })
      .finally(() => {
        commit('setLoadingAuthorizerUser', false);
      });
  },
};

export default {
  state: {
    currentUser: null,
    authorizerUser: null,
    loadingAuthorizerUser: false,
    isAdmin: false,
  },
  actions,
  mutations,
  getters,
};
