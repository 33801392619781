import api from './index';

const basePath = (entityId) => `/api/v1/entities/${entityId}`;

export default {
  getEntityConfiguration(entityId) {
    return api({
      method: 'get',
      url: `${basePath(entityId)}/entity_configuration`,
    });
  },
  updateBalancesConfiguration(entityId, balancesConfiguration) {
    return api({
      method: 'patch',
      data: { balancesConfiguration },
      url: `${basePath(entityId)}/balances_configuration`,
    });
  },
  deleteEntity(entityId) {
    return api({
      method: 'delete',
      url: `${basePath(entityId)}`,
    });
  },
  createEntity(name) {
    const data = { entity: { name } };

    return api({
      method: 'post',
      data,
      url: '/api/v1/entities',
    });
  },
};
