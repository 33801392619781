import axios, { type AxiosRequestTransformer, type AxiosResponseTransformer } from 'axios';
import { convertKeys } from '@/src/utils/case-converter';
import { csrfToken } from '@/src/utils/csrf-token';
import { paramsSerializer } from '@/src/utils/query-params';

export default axios.create({
  headers: {
    'X-CSRF-Token': csrfToken(),
  },
  transformRequest: [
    data => convertKeys(data, 'decamelize'),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    data => convertKeys(data, 'camelize'),
  ],
  paramsSerializer,
});
