import { camelize } from 'humps';
import { capitalize, isEmpty } from 'lodash';
import MovementsApi from '@/src/api/bank-movements';
import AccountsApi from '@/src/api/bank-accounts';
import { parseEnum, parseDate, parseName, parseType } from '@/helpers/parseExtraColumnQueryParams';
import { keyParam, addParamToUrl, keyParamEnum } from '@/helpers/createExtraColumnQueryParams';

const initialState = {
  movements: [],
  movementsPage: 1,
  pageItems: undefined,
  loading: false,
  movement: undefined,
  movementComments: [],
  loadingMovement: false,
  loadingComments: false,
  accountsFilters: {},
  postDateFilter: undefined,
  institutionFilter: 'all',
  typeFilter: 'all',
  fromAmountFilter: undefined,
  toAmountFilter: undefined,
  amountFilter: undefined,
  inboundFilter: true,
  outboundFilter: true,
  descriptionFilter: undefined,
  statusFilter: '',
  booleanExtraColumnsFilters: {},
  enumExtraColumnsFilters: {},
  textExtraColumnsFilters: {},
  dateExtraColumnsFilters: {},
  unseenMovements: [],
  userDefaultFilters: {},
  lastMovement: undefined,
  seenMovements: [],
  selectedMovements: [],
};

export const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setLoadingMovement(state, payload) {
    state.loadingMovement = payload;
  },
  setLoadingComments(state, payload) {
    state.loadingComments = payload;
  },
  setMovement(state, payload) {
    state.movement = payload;
  },
  setMovementComments(state, payload) {
    state.movementComments = payload;
  },
  updateMovement(state, payload) {
    const index = state.movements.findIndex(movement => movement.id === payload.id);
    const previousStatus = state.movements[index].status;
    const newStatus = payload.status;
    const amount = Math.abs(payload.amount);
    if (previousStatus === 'pending' && newStatus === 'approved') {
      state.accountsFilters[payload.bankAccount.id].pendingBalance -= amount;
    } else if (previousStatus === 'approved' && newStatus === 'pending') {
      state.accountsFilters[payload.bankAccount.id].pendingBalance += amount;
    }
    Object.assign(state.movements[index], payload);
  },
  updateMovementDefaultedExtraColumn(state, payload) {
    const index = state.movements.findIndex(movement => movement.id === payload.id);
    const columnIndex = state.movements[index].defaultedExtraColumns.findIndex(
      extraColumn => extraColumn.name === payload.name,
    );
    state.movements[index].defaultedExtraColumns[columnIndex].value = payload.value;
  },
  setMovements(state, payload) {
    state.movements = payload;
  },
  setMovementsPage(state, payload) {
    state.movementsPage = payload;
  },
  setFilter(state, payload) {
    state[`${payload.key}Filter`] = payload.value;
  },
  setBooleanExtraColumnsFilter(state, payload) {
    state.booleanExtraColumnsFilters = { ...state.booleanExtraColumnsFilters, [`${payload.key}`]: { ...payload } };
  },
  setEnumExtraColumnsFilter(state, payload) {
    const filters = state.enumExtraColumnsFilters[`${payload.key}`] || {};
    filters[`${payload.enum}`] = payload.value;
    state.enumExtraColumnsFilters = { ...state.enumExtraColumnsFilters, [`${payload.key}`]: { ...filters } };
  },
  setTextExtraColumnsFilter(state, payload) {
    state.textExtraColumnsFilters = { ...state.textExtraColumnsFilters, [`${payload.key}`]: { ...payload } };
  },
  setDateExtraColumnsFilter(state, payload) {
    state.dateExtraColumnsFilters = { ...state.dateExtraColumnsFilters, [`${payload.key}`]: { ...payload } };
  },
  setPageItems(state, payload) {
    state.pageItems = payload;
  },
  resetFilters(state) {
    const initialFilters = [
      ['postDate', undefined],
      ['institution', 'all'],
      ['rut', undefined],
      ['type', 'all'],
      ['fromAmount', undefined],
      ['toAmount', undefined],
      ['amount', undefined],
      ['inbound', true],
      ['outbound', true],
      ['description', undefined],
      ['comment', undefined],
      ['status', ''],
    ];
    initialFilters.forEach(([key, filter]) => {
      state[`${key}Filter`] = filter;
    });
    state.booleanExtraColumnsFilters = {};
    state.enumExtraColumnsFilters = {};
    state.textExtraColumnsFilters = {};
    state.dateExtraColumnsFilters = {};
  },
  initialAccountsFilters(state, payload) {
    state.accountsFilters = payload;
  },
  initialExtraColumnsFilterBoolean(state, payload) {
    state.booleanExtraColumnsFilters[`${payload.name}`] = payload.value;
  },
  initialExtraColumnsFilterEnum(state, payload) {
    state.enumExtraColumnsFilters[`${payload.name}`] = payload.value;
  },
  initialExtraColumnsFilterDate(state, payload) {
    state.dateExtraColumnsFilters[`${payload.name}`] = payload.value;
  },
  setAccountFilter(state, payload) {
    state.accountsFilters[payload].selected = !state.accountsFilters[payload].selected;
  },
  alterAccountFilter(state, payload) {
    state.accountsFilters[payload.accountId].selected = payload.value;
  },
  setEntityConfiguration(state, payload) {
    const columns = payload.hiddenColumns.map(name => camelize(name));
    state.entityConfiguration = { ...payload, hiddenColumns: columns };
  },
  setLastMovement(state, payload) {
    state.lastMovement = payload;
  },
  setUnseenMovements(state, payload) {
    if (payload.length > 0 && state.lastMovement !== undefined) {
      const newMovements = payload.filter(movement => state.lastMovement.id < movement.id).map(movement => movement.id);
      const seenMovements = new Set(state.seenMovements);
      state.unseenMovements = newMovements.filter(movement => !seenMovements.has(movement));
    }
  },
  removeSeenMovement(state, payload) {
    const index = state.unseenMovements.indexOf(payload.id);
    if (index !== -1) {
      state.unseenMovements.splice(index, 1);
      state.seenMovements.push(payload.id);
    }
  },
  setSelectedMovements(state, payload) {
    state.selectedMovements = payload;
  },
};

export const actions = {
  refreshSelectedMovements({ commit }, selectedMovements) {
    commit('setSelectedMovements', selectedMovements);
  },
  approveAll({ commit, rootState, dispatch, getters }, content = '') {
    commit('setLoading', true);

    return MovementsApi.approveAllMovements({
      entityId: rootState.entities.entityId,
      params: {
        ...getters.currentFilters,
        content,
      },
    }).then(() => {
      dispatch('getMovements');
      dispatch('getAccounts');
    }).finally(() => {
      commit('setLoading', false);
    });
  },
  approveAllSelected({ state, dispatch }, content = '') {
    state.selectedMovements.forEach(movement => dispatch('approveMovement',
      { movementId: movement.movementId, content },
    ));
  },
  getMovements({ commit, rootState, getters }) {
    commit('setLoading', true);

    return MovementsApi.getMovements(getters.currentFilters, rootState.entities.entityId)
      .then(({ data: { movements }, headers }) => {
        commit('setUnseenMovements', movements);
        commit('setMovements', movements);
        commit('setPageItems', Number(headers['page-items']));
        sessionStorage.setItem('filters', JSON.stringify(getters.currentFilters));
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  getAccounts({ commit, rootState }) {
    return AccountsApi.getAccounts(rootState.entities.entityId)
      .then(({ data: { accounts } }) => {
        const accountsFilters = {};
        accounts.forEach(account => {
          if (account.isActive) {
            accountsFilters[account.id] = {
              institution: account.institution,
              type: account.accountType,
              customName: account.customName,
              number: account.number,
              selected: true,
              isUpdateable: account.isUpdateable,
              fintocRefreshedAt: account.fintocRefreshedAt,
              holderName: account.holderName,
              rut: account.rut,
              availableBalance: account.availableBalance,
              currentBalance: account.currentBalance,
              pendingBalance: account.pendingBalance,
              currency: account.currency,
              loginRequired: account.loginRequired,
            };
          }
        });
        commit('initialAccountsFilters', accountsFilters);

        return { accounts };
      });
  },
  getMovement({ commit, rootState, dispatch }, movementId) {
    commit('setLoadingMovement', true);

    return MovementsApi.getMovement({
      entityId: rootState.entities.entityId,
      movementId,
    })
      .then(({ data: { bankMovement } }) => {
        commit('setMovement', bankMovement);

        return bankMovement;
      })
      .then((bankMovement) => {
        const checksLength = bankMovement.bankMovementChecks.length;
        const lastCheck = checksLength ? bankMovement.bankMovementChecks[checksLength - 1] : undefined;
        if (lastCheck && !lastCheck.removedAt) {
          dispatch('getAuthorizerUser', lastCheck.userId);
        } else {
          commit('setAuthorizerUser', undefined);
        }

        return bankMovement;
      })
      .finally((bankMovement) => {
        commit('setLoadingMovement', false);

        return bankMovement;
      });
  },
  getComments({ commit, rootState }, movementId) {
    commit('setLoadingComments', true);

    return MovementsApi.getComments({
      entityId: rootState.entities.entityId,
      movementId,
    })
      .then(({ data: { comments } }) => {
        commit('setMovementComments', comments);
      })
      .finally(() => {
        commit('setLoadingComments', false);
      });
  },
  approveMovement({ commit, dispatch, rootState }, { movementId, content }) {
    return MovementsApi.changeStatus({ movementId, entityId: rootState.entities.entityId }).then((user) => {
      const movement = rootState.movements.movement;
      if (movement) {
        movement.status = 'approved';
        commit('setMovement', movement);
        commit('setAuthorizerUser', user);
      }
      if (content) {
        dispatch('addComment', { movementId, content });
      }
    });
  },
  removeCheck({ commit, rootState }, { movementId }) {
    return MovementsApi.removeCheck({ movementId, entityId: rootState.entities.entityId }).then(() => {
      const movement = rootState.movements.movement;
      movement.status = 'pending';
      commit('updateMovement', movement);
      commit('setMovement', movement);
      commit('setAuthorizerUser', undefined);
    });
  },
  addComment({ commit, rootState, dispatch }, { movementId, content }) {
    commit('setLoadingComments', true);

    return MovementsApi.postComment({
      entityId: rootState.entities.entityId,
      movementId,
      content })
      .then(({ data: { comment } }) => {
        const comments = rootState.movements.movementComments;
        comments.push(comment);
        commit('setMovementComments', comments);
        dispatch('increaseCommentCount');
      })
      .finally(() => {
        commit('setLoadingComments', false);
      });
  },
  deleteComment({ commit, rootState, dispatch }, { movementId, commentId }) {
    commit('setLoadingComments', true);

    return MovementsApi.deleteComment({
      entityId: rootState.entities.entityId,
      movementId,
      commentId })
      .then(() => {
        dispatch('getComments', movementId);
        dispatch('decreaseCommentCount');
      })
      .finally(() => {
        commit('setLoadingComments', false);
      });
  },
  getLastMovement({ commit, rootState }) {
    return MovementsApi.getLastMovement({
      entityId: rootState.entities.entityId,
    })
      .then(({ data: { bankMovement } }) => {
        if (bankMovement) {
          commit('setLastMovement', bankMovement);
        }
      });
  },
  updateMovement({ commit }, movement) {
    commit('updateMovement', movement);
  },
  updateMovementDefaultedExtraColumn({ commit }, movement) {
    commit('updateMovementDefaultedExtraColumn', movement);
  },
  increaseCommentCount({ commit, state }) {
    const movement = state.movement;
    movement.commentCount += 1;
    commit('updateMovement', movement);
  },
  decreaseCommentCount({ commit, state }) {
    const movement = state.movement;
    movement.commentCount -= 1;
    commit('updateMovement', movement);
  },
  nextMovementsPage({ commit, state }) {
    commit('setMovementsPage', state.movementsPage + 1);
  },
  previousMovementsPage({ commit, state }) {
    commit('setMovementsPage', state.movementsPage - 1);
  },
  goToMovementsPage({ commit }, page) {
    commit('setMovementsPage', page);
  },
  changeFilter({ commit, dispatch }, filter) {
    commit('setFilter', filter);
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  booleanChangeExtraColumnsFilter({ commit, dispatch }, filter) {
    commit('setBooleanExtraColumnsFilter', filter);
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  enumChangeExtraColumnsFilter({ commit, dispatch }, filter) {
    commit('setEnumExtraColumnsFilter', filter);
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  enumChangeAllExtraColumnsFilter({ dispatch, commit, state }, filter) {
    Object.keys(state.enumExtraColumnsFilters[filter.key]).forEach(enumName => {
      if (state.enumExtraColumnsFilters[filter.key][enumName] !== filter) {
        const payload = { key: filter.key, enum: enumName, value: filter.value };
        commit('setEnumExtraColumnsFilter', payload);
      }
    });
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  textChangeExtraColumnsFilter({ commit }, filter) {
    commit('setTextExtraColumnsFilter', filter);
    commit('setMovementsPage', 1);
  },
  dateChangeExtraColumnsFilter({ commit, dispatch }, filter) {
    commit('setDateExtraColumnsFilter', filter);
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  changeAccountFilter({ commit, dispatch }, accountId) {
    commit('setAccountFilter', accountId);
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  clearFilters({ commit, dispatch, state }) {
    Object.keys(state.accountsFilters).forEach(id => {
      commit('alterAccountFilter', { accountId: id, value: true });
    });
    commit('resetFilters');
    dispatch('getInitialExtraColumnsFilters');
    commit('setMovementsPage', 1);
    dispatch('loadDefaultFilters');
    dispatch('getMovements');
  },
  setFiltersFromUrl({ dispatch, commit, state }) {
    const textFields = [
      'comment',
      'description',
      'fromAmount',
      'toAmount',
      'amount',
      'type',
      'rut',
      'institution',
    ];
    const dateFields = [
      'postDate',
    ];
    const booleanFields = [
      'inbound',
      'outbound',
    ];
    const selectFields = [
      'status',
    ];

    const params = new URLSearchParams(window.location.search);
    // eslint-disable-next-line max-statements
    [...params.entries()].forEach(([key, filter]) => {
      const fieldName = camelize(key.replace('[]', ''));
      if (textFields.includes(fieldName) && !state.userDefaultFilters[fieldName]) {
        commit('setFilter', { key: fieldName, value: filter });
      } else if (selectFields.includes(fieldName) && !state.userDefaultFilters[fieldName]) {
        commit('setFilter', { key: fieldName, value: filter });
      } else if (dateFields.includes(fieldName) && state.userDefaultFilters[fieldName].length === 0) {
        if (filter) {
          const date = filter.split(',').map(d => new Date(d));
          commit('setFilter', { key: fieldName, value: date });
        }
      } else if (booleanFields.includes(fieldName) && state.userDefaultFilters[fieldName] !== false) {
        // eslint-disable-next-line eqeqeq
        commit('setFilter', { key: fieldName, value: filter == 'true' });
      } else if (fieldName === 'accounts' && state.userDefaultFilters[fieldName].length === 0) {
        if (filter !== undefined) {
          const ids = filter.split(',');
          Object.keys(state.accountsFilters).forEach(id => {
            if (!ids.includes(`${id}`)) {
              commit('alterAccountFilter', { accountId: id, value: false });
            }
          });
        }
      } else if (fieldName.includes('ExtraColumn')) {
        dispatch('setExtraColumnFiltersFromUrl', { key: fieldName, value: filter });
      } else if (fieldName === 'page') {
        commit('setMovementsPage', parseInt(filter, 10));
      }
    });
  },
  setExtraColumnFiltersFromUrl({ commit }, payload) {
    const columnName = parseName(payload.key);
    const columnType = parseType(payload.key);
    let param;
    if (columnType === 'boolean') {
      param = { key: columnName, value: payload.value };
    } else if (columnType === 'enum') {
      param = { key: columnName, enum: parseEnum(payload.key), value: payload.value === 'true' };
    } else if (columnType === 'text') {
      param = { key: columnName, value: payload.value };
    } else if (columnType === 'date') {
      const dates = parseDate(payload.value);
      param = { key: columnName, startDate: dates.start, endDate: dates.end };
    }
    commit(`set${capitalize(columnType)}ExtraColumnsFilter`, param);
  },
  setFiltersFromStorage({ dispatch, getters }) {
    if (sessionStorage.getItem('filters')) {
      const filters = JSON.parse(sessionStorage.getItem('filters'));
      const currentFilters = getters.currentFilters;
      const params = new URLSearchParams();
      Object.entries(filters).forEach(([fieldName, filter]) => {
        if (currentFilters[fieldName] !== filter) {
          if (fieldName === 'accounts') {
            params.set(`${fieldName}[]`, filter);
          } else if (fieldName.includes('ExtraColumn')) {
            dispatch('setExtraColumnsFiltersFromStorage', { param: params, key: fieldName, filterArray: filter });
          } else {
            params.set(fieldName, filter);
          }
        }
      });
      window.history.replaceState(
        {}, '', `${location.pathname}?${params}`,
      );
      dispatch('setFiltersFromUrl');
    }
  },
  setExtraColumnsFiltersFromStorage({ getters }, payload) {
    const columnType = parseType(payload.key);
    payload.filterArray.forEach((filter) => {
      const queryKey = keyParam(filter.key, columnType);
      if (columnType === 'boolean') {
        addParamToUrl(payload.param, { key: queryKey, value: filter.value });
      } else if (columnType === 'enum') {
        const options = getters.extraColumnsSchema[filter.key].options.tags;
        options.forEach((tag) => {
          const queryKeyEnum = keyParamEnum(filter.key, columnType, tag);
          addParamToUrl(payload.param,
            { key: queryKeyEnum, value: filter.values.includes(tag) });
        });
      } else if (columnType === 'text') {
        addParamToUrl(payload.param, { key: queryKey, value: filter.value });
      } else if (columnType === 'date') {
        addParamToUrl(payload.param,
          { key: queryKey, value: `${filter.startDate},${filter.endDate}` });
      }
    });
  },
  setDefaultFilters({ state }, userDefaultFilters) {
    if (userDefaultFilters) {
      Object.keys(userDefaultFilters).forEach(key => {
        state.userDefaultFilters[camelize(key)] = userDefaultFilters[key];
      });
    } else {
      state.userDefaultFilters = {
        description: undefined,
        fromAmount: undefined,
        toAmount: undefined,
        amount: undefined,
        type: undefined,
        institution: undefined,
        status: undefined,
        inbound: undefined,
        outbound: undefined,
        accounts: [],
        postDate: [],
      };
    }
  },

  loadDefaultFilters({ commit, state }) {
    const textFilters = [
      'description',
      'fromAmount',
      'toAmount',
      'amount',
      'type',
      'institution',
      'status'];

    const boolFilters = [
      'inbound',
      'outbound'];

    const arrayFilters = [
      'accounts'];

    const dateFilters = [
      'postDate'];

    Object.keys(state.userDefaultFilters).forEach(key => {
      if (textFilters.includes(key)) {
        if (state.userDefaultFilters[key] !== undefined) {
          state[`${key}Filter`] = state.userDefaultFilters[key];
        }
      } else if (boolFilters.includes(key)) {
        if (state.userDefaultFilters[key] === false) {
          state[`${key}Filter`] = state.userDefaultFilters[key];
        }
      } else if (arrayFilters.includes(key)) {
        if (state.userDefaultFilters[key].length > 0) {
          Object.keys(state.accountsFilters).forEach(id => {
            if (!state.userDefaultFilters[key].includes(Number(id))) {
              commit('alterAccountFilter', { accountId: id, value: false });
            }
          });
        }
      } else if (dateFilters.includes(key)) {
        if (state.userDefaultFilters[key].length > 0) {
          state[`${key}Filter`] = state.userDefaultFilters[key].map(d => new Date(d));
        }
      }
    });
  },
  changeAllAccountsFilters({ dispatch, commit, state }, value) {
    Object.keys(state.accountsFilters).forEach(key => {
      if (state.accountsFilters[key].selected !== value) {
        commit('setAccountFilter', key);
      }
    },
    );
    commit('setMovementsPage', 1);
    dispatch('getMovements');
  },
  getInitialExtraColumnsFilters({ commit, getters }) {
    const schema = getters.extraColumnsSchema;
    Object.values(schema).forEach((columnSchema) => {
      let payload;

      switch (columnSchema.type) {
      case 'Boolean':
        payload = { name: columnSchema.name, value: { key: columnSchema.name, value: undefined } };
        commit('initialExtraColumnsFilterBoolean', payload);
        break;
      case 'Enum':
        columnSchema.options.tags.forEach((tagName) => {
          payload = { 'key': columnSchema.name, 'value': true, 'enum': tagName };
          commit('setEnumExtraColumnsFilter', payload);
        });
        break;
      case 'String':
        payload = { 'key': columnSchema.name, value: '' };
        commit('setTextExtraColumnsFilter', payload);
        break;
      case 'Date':
        payload = { name: columnSchema.name,
          value: { key: columnSchema.name, startDate: undefined, endDate: undefined } };
        commit('initialExtraColumnsFilterDate', payload);
        break;
      default:
        break;
      }
    });

    return schema;
  },
  removeSeenMovement({ commit }, movement) {
    commit('removeSeenMovement', movement);
  },
};

export const getters = {
  visibleColumn: (state) => (columnName) => {
    const configuration = state.entityConfiguration;
    const hiddenColumns = configuration && configuration.hiddenColumns;

    return hiddenColumns && !hiddenColumns.includes(columnName);
  },
  activeAccounts(state) {
    const activeAccounts = Object.keys(state.accountsFilters).filter(key => (state.accountsFilters[key].selected));
    if (Object.keys(state.accountsFilters).length === 0 || activeAccounts.length === 0) {
      return [null];
    }

    return activeAccounts;
  },
  getExtraColumnsFiltersBoolean(state) {
    let filters = Object.values(state.booleanExtraColumnsFilters);
    filters = filters
      .filter(x => x !== undefined && x !== {})
      .filter(x => x.value !== 'all' && x.value !== undefined);

    return isEmpty(filters) ? undefined : filters;
  },
  getExtraColumnsFiltersEnum(state) {
    const keys = Object.keys(state.enumExtraColumnsFilters);
    const filters = {};

    keys.forEach((enumName) => {
      const columnFilters = state.enumExtraColumnsFilters[enumName] || {};
      const filterData = [];
      const numFilters = Object.entries(columnFilters).length;
      Object.entries(columnFilters).forEach(([optionName, selected]) => {
        if (selected === true) {
          filterData.push(optionName);
        }
      });
      if (filterData.length > 0 && filterData.length !== numFilters) {
        filters[enumName] = { 'key': enumName, 'values': filterData };
      } else if (filterData.length === 0 && numFilters > 0) {
        filters[enumName] = { 'key': enumName, 'values': filterData };
      }
    });

    return isEmpty(filters) ? undefined : Object.values(filters);
  },
  getExtraColumnsFiltersText(state) {
    let filters = Object.values(state.textExtraColumnsFilters);
    filters = filters
      .filter(x => x !== undefined && x.value !== '');

    return isEmpty(filters) ? undefined : filters;
  },
  getExtraColumnsFiltersDate(state) {
    let filters = Object.values(state.dateExtraColumnsFilters);
    filters = filters
      .filter(x => x !== undefined && x !== {})
      .filter(x => x.startDate !== undefined && x.endDate !== undefined);

    return isEmpty(filters) ? undefined : filters;
  },
  booleanGetExtraColumnsFilterValue(state) {
    return (columnName) => (
      state.booleanExtraColumnsFilters[columnName] && state.booleanExtraColumnsFilters[columnName].value
    );
  },
  enumAllExtraColumnsOptionsSelected(state) {
    return (columnName) => {
      const filters = state.enumExtraColumnsFilters[columnName] || {};
      const values = Object.values(filters).filter(x => x === false);

      return !(values.length > 0);
    };
  },
  enumGetExtraColumnsFilterValue(state) {
    return (columnName, tagOption) => (
      state.enumExtraColumnsFilters[columnName] && state.enumExtraColumnsFilters[columnName][tagOption]
    );
  },
  textGetExtraColumnsFilterValue(state) {
    return (columnName) => (
      state.textExtraColumnsFilters[columnName] && state.textExtraColumnsFilters[columnName].value
    );
  },
  dateGetExtraColumnsFilterValue(state) {
    return (columnName) => (
      state.dateExtraColumnsFilters[columnName] &&
      [state.dateExtraColumnsFilters[columnName].startDate, state.dateExtraColumnsFilters[columnName].endDate]
    );
  },
  currentFilters(state) {
    const normalizeFilter = (filter, defaultString = '') => {
      if (filter === defaultString || filter === null) return undefined;

      return filter;
    };

    const params = {
      page: state.movementsPage,
      accounts: getters.activeAccounts(state),
      postDate: state.postDateFilter,
      institution: normalizeFilter(state.institutionFilter, 'all'),
      type: normalizeFilter(state.typeFilter, 'all'),
      from: normalizeFilter(state.fromAmountFilter),
      to: normalizeFilter(state.toAmountFilter),
      amount: normalizeFilter(state.amountFilter),
      inbound: state.inboundFilter === true ? undefined : false,
      outbound: state.outboundFilter === true ? undefined : false,
      description: normalizeFilter(state.descriptionFilter),
      status: normalizeFilter(state.statusFilter),
      booleanExtraColumns: getters.getExtraColumnsFiltersBoolean(state),
      enumExtraColumns: getters.getExtraColumnsFiltersEnum(state),
      textExtraColumns: getters.getExtraColumnsFiltersText(state),
      dateExtraColumns: getters.getExtraColumnsFiltersDate(state),
    };

    return params;
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
