import api from './index';

const basePath = (userId) => `/api/v1/users/${userId}`;

export default {
  getUser(userId) {
    const res = api({
      method: 'get',
      url: `${basePath(userId)}`,
    });

    return res;
  },

  sendEvent(userId, event) {
    const path = `${basePath(userId)}/state_event`;
    const res = api({
      method: 'patch',
      data: {
        id: userId,
        event,
      },
      url: path,
    });

    return res;
  },

  updateUser(userId, params) {
    const path = `${basePath(userId)}`;
    const res = api({
      method: 'patch',
      data: params,
      url: path,
    });

    return res;
  },
};
